/* You can add global styles to this file, and also import other style files */
html,
body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif !important;
}

@import "primeicons/primeicons.css";
@import "assets/styles/primeng/primeng-styles.scss";
@import "assets/styles/default/default-main-styles.scss";
@import "assets/styles/components/_booking.scss";


.cursor-pointer {
  cursor: pointer;
}