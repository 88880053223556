// Primary color

$color-primary: #025928;

// Secundary color

$color-secondary: #f2b705;

// Gray colors

$color-gray-1: #f9f9f9;
$color-gray-2: #d9d9d9;
$color-gray-3: #acacac;
$color-gray-4: #a9a9a9;
$color-gray-5: #7c7c7c;
$color-gray-6: #454545;
$color-gray-7: #1e1e1e;
$color-gray-8: #000000;
$color-gray-9: #ffffff;

// Menu colors

$alerts: rgba(34, 180, 31, 0.19);
$submenu: rgba(2, 89, 40, 0.55);
$hover-submenu: rgba(65, 122, 45, 1);

// default gray

//-----------------------------------//

// color text

.color-text-primary {
  color: $color-primary !important;
}

.color-text-seconday {
  color: $color-secondary !important;
}

.color-text-tertiary {
  color: $color-gray-5;
}

.color-text {
  color: $color-gray-2;
}

.color-text-modal {
  color: $color-gray-7;
}

.color-text-form {
  color: $color-gray-8;
}

.color-text-table {
  color: #575F6E;
}

.color-text-user {
  color: #8C9195;
}

.color-subtitle-modal {
  color: #353535;
}



