@import "./default-fonts";

.storybook-button {
  @extend .poppins-semibold;
  font-weight: 700;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  opacity: 0px;
}

/*------------Primary-----------------*/
.storybook-button--primary {
  background: #025928;
  // padding: 8px 64px 8px 64px;
  color: white;
  border: 1px solid #025928;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  gap: 4px;
  box-shadow: none;

  &.add-button {
    width: 140px;
    border-radius: 4px;
  }

  &.form {
    width: 100px;
  }

  &.with-state {
    border-radius: 40px;
    width: auto;
    font-size: 16px;
    padding: 4px 8px;
    opacity: 0.5;
  }

  &.button-modal {
    padding: 12px 24px;
    width: 212px;
  }
}

.storybook-button--primary:hover {
  background: #417a2d;
  color: white;
}

/*------------Secondary-----------------*/
.storybook-button--secondary {
  background: white;
  // padding: 8px 64px 8px 64px;
  color: #025928;
  border: 2px solid #025928;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  border-radius: 4px;
  gap: 4px;
  box-shadow: none;

  &.add-login {
    padding: 8px 6px;
  }

  &.add-button {
    width: 140px;
    border-radius: 4px;
  }

  &.button-modal {
    padding: 12px 24px;
    border-radius: 0px;
    width: 212px;
  }
}


.storybook-button--secondary:hover {
  background: #78c95b;
}

/*------------tertiary----------------*/
.storybook-button--tertiary {
  background: white;
  // padding: 8px 64px 8px 64px;
  color: #025928;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  border-radius: 0;
  gap: 4px;
  box-shadow: none;

  &.add-button {
    width: 180px;
  }
}

.storybook-button--tertiary:hover {
  background: rgb(2 89 40 / 6%);
  color: #025928;
  border-color: transparent;
}

/*------------Google-----------------*/
.storybook-button--google {
  background: #ffffff;
  border-radius: 0;
  color: #7c7c7c;
  border: 1px solid #7c7c7c;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: auto;
  width: 100%;

  &.add-google {
    padding: 6px 8px;
  }

  &.form {
    width: 100% !important;
  }
}

.storybook-button--google:hover {
  background: #639bf5;
  border-radius: 0;
  color: #ffffff;
}

.view-detail-button {
  background-color: transparent;
  border: none;
}

.storybook-button--accept {
  background: #3662fa;
  color: white;
  border-radius: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  width: 10px;

  &.add-button {
    width: 100px;
  }
}

.storybook-button--reject {
  background: #e11900;
  color: white;
  border-radius: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  width: 10px;

  &.add-button {
    width: 100px;
  }
}

.icon-btn-sm-icon {
  background: none;
  color: #000;
  border: none;
  width: 20px;
  height: 8px;
  cursor: default;
}